import React from 'react';
import PropTypes from 'prop-types';
import withContext from 'store/withContext';
import { ComponentWrapper } from './PicksBansCard.css';

const PicksBansCard = (props) => {
  const { team_1_name, team_2_name, data } = props;
  return (
    <ComponentWrapper className="c-PicksBansCard">
      <h4>Picks and Bans</h4>
      <div className="teams">
        <div className="team-1">
          {team_1_name}
          <img src={`https://webcdn.hirezstudios.com/smite-esports/dev/teams/${props.context.darkTheme ? 'dark/' : ''}${team_1_name}-70x70.png`} alt={team_1_name} />
        </div>
        <div className="team-2">
        <img src={`https://webcdn.hirezstudios.com/smite-esports/dev/teams/${props.context.darkTheme ? 'dark/' : ''}${team_2_name}-70x70.png`} alt={team_2_name} />
        {team_2_name} 
        </div>
        </div>
      {data && data.map((choice,i) => 
        <div key={choice.type + choice.team + choice.god + i}className={`item ${choice.type} ${choice.team === team_1_name ? 'right' : 'left'}`}>
          <div className="god">
            <p>{choice.god}</p>
            <a href={`https://www.smitegame.com/gods/${choice.god.toLowerCase().replace(/\s+/g, '-')}`} target="_blank">
              <img src={`https://webcdn.hirezstudios.com/smite/god-icons/${choice.god.toLowerCase().replace(/\s+/g, '-')}.jpg`} alt={choice.god} />
            </a>
          </div>
          <div className="type">{choice.type}</div>
          </div>
        )
      }
    </ComponentWrapper>
  );
};

PicksBansCard.propTypes = {
  team_1_name: PropTypes.string,
  team_2_name: PropTypes.string,
  data: PropTypes.array.isRequired
}

export default withContext(PicksBansCard);