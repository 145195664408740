import styled from 'styled-components';

export const ComponentWrapper = styled.div`
  background: var(--background);
  box-shadow: 0px .3rem .6rem rgba(0, 0, 0, 0.16);
  box-sizing: border-box;
  max-width: 53rem;
  padding: 2rem;
  width: 100%;
  @media only screen and (max-width: 1423px) {
    margin: 0 auto;
  }
  h4 {
    font-family: var(--font-body);
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
  }
  .player-card {
    align-items: center;
    display: flex;
    margin: 1rem -1rem;
    img {
      border-radius: 20rem;
      height: 5rem;
      margin-right: 0.5rem;
      overflow: hidden;
      width: 5rem;
    }
    .fallback {
      background: center no-repeat;
      background-size: cover;
      display: inline-block;
      min-height: 5rem;
      min-width: 5rem;
    }
    .cc_image {
      background: center no-repeat;
      background-size: cover;
      min-height: 5rem;
      min-width: 5rem;
    }
    .team-image {
      background: white;
      width: 1.5rem;
      height: 1.5rem;
      padding: 0.25rem;
      position: relative;
      left: 3rem;
      top: -2rem;
      @media only screen and (max-width: 1440px) {
        left: -2.5rem;
        top: 0.5rem;
      }
    }
    .info {
      display: flex;
      font-size: 1.4rem;
      flex-direction: column;
      width: 10rem;
      div:first-of-type {
        text-transform: uppercase;
      }
    }
    .stats {
      background: var(--primary);
      color: var(--background);
      font-size: 2.2rem;
      font-weight: bold;
      padding: 1.2rem 1.9rem;
      margin-left: 3.5rem;
      text-align: center;
      min-width: 13rem;
    }
  }
`;