import React from 'react';
import PropTypes from 'prop-types';
import { ComponentWrapper } from './TeamCard.css';
import { Link } from 'gatsby';

const TeamCard = (props) => {
  const { name, logo, tag } = props;
	return (
    <ComponentWrapper className="c-TeamCard">
      <Link to={`/teams/${tag.toLowerCase()}`}>
        <div className="logo">
          <img src={logo} alt={name} />
        </div>
        <div className="name">{name}</div>
      </Link>
    </ComponentWrapper>
	); 
};

TeamCard.propTypes = {
  name: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  tag: PropTypes.string.isRequired
}

export default TeamCard;