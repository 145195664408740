import React from 'react';
import { Link } from 'gatsby';
import withContext from 'store/withContext';
import PropTypes from 'prop-types';
import { ComponentWrapper } from './ScheduleDateBlock.css';

const ScheduleDateBlock = (props) => {
  const { schedule, date_range, stream_url,phase } = props;
	return (
    <ComponentWrapper className="c-ScheduleDateBlock">
      {date_range &&
        <div className="date-range">{date_range}</div>
      }
      <div className="schedule">
        {schedule && schedule.map((item,i)=>
          <div id={`match_${item.date}`} key={item.date + i} className="day">
            <div className="date">{new Date(item.date*1000).toLocaleDateString(navigator.language,{weekday: 'long', month: 'long', day: 'numeric'})}</div>
              <div className="match">
                <div className="table">
                  <div className="thead">
                    <div className="tr">
                      <div className="th">Matchup</div>
                      <div className="th">Game Info</div>
                      <div className="th">Watch</div>
                    </div>
                  </div>
                  <div className="tbody">
                  {item.matches.filter((day)=>{
                        if (day.phase == phase) {
                          return true;
                        }
                        else false;
                      }).map((match_item, i) =>
                    <div className="tr" key={match_item.time + match_item.team_1_shortname + match_item.team_2_shortname + i}>
                      <div className="td">
                        {/* <img src={`https://webcdn.hirezstudios.com/esports/teams/${match_item.team_1_shortname.toLowerCase()}-70x70.png`} alt={match_item.team_1_shortname} /> */}
                        <img src={`https://webcdn.hirezstudios.com/smite-esports/dev/teams/${props.context.darkTheme ? 'dark/' : ''}${match_item.team_1_shortname}.png`} />
                        <div className="name">{match_item.team_1_name}</div>
                        <div className="name mobile">{match_item.team_1_shortname}</div>
                        <strong> VS </strong>
                        {/* <img src={`https://webcdn.hirezstudios.com/esports/teams/${match_item.team_2_shortname.toLowerCase()}-70x70.png`} alt={match_item.team_2_shortname} /> */}
                        <img src={`https://webcdn.hirezstudios.com/smite-esports/dev/teams/${props.context.darkTheme ? 'dark/' : ''}${match_item.team_2_shortname}.png`} />
                        <div className="name">{match_item.team_2_name}</div>
                        <div className="name mobile">{match_item.team_2_shortname}</div>
                      </div>
                      <div className="td game-info splr">
                        <div className={`actions ${match_item.team_1_score || match_item.team_2_score ? '' : 'is-live'}`}>
                          {match_item.team_1_score || match_item.team_2_score ?
                            <>
                             <div className="schedule-result">{`${match_item.team_1_shortname} ${match_item.team_1_score} - ${match_item.team_2_score} ${match_item.team_2_shortname}`} </div> 
                            </>
                            :
                            <>
                              <div>{new Date(match_item.time*1000).toLocaleTimeString(navigator.language,{ hour: 'numeric', minute:'2-digit',hour12: true, timeZoneName: 'short' })}</div>
                            </>
                          }
                        </div>
                      </div>
                      <div className="td links">
                        <div className={`link ${(match_item.team_1_score !== null && match_item.team_2_score !== null) ? '' : 'full'}`}>
                            {match_item.playlist_url ?
                              <>
                                <a className="play" href={match_item.playlist_url}><i className="icon-play" />Videos</a>
                              </>
                              :
                              <>
                                <div className="twitch">Twitch</div>
                              </>
                            }
                          </div>
                          {(match_item.team_1_score !== null && match_item.team_2_score !== null && match_item.winning_team !== null ) &&
                          <Link className="link results" to={`/matches/${match_item.match_id}`}><span><i className="icon-calendar" />Full Results</span></Link>
                        }
                      </div>
                    </div>
                    )}
                  </div>
                </div>
              </div>
          </div>
        )}      
      </div>
    </ComponentWrapper>
	); 
};

ScheduleDateBlock.propTypes = {
  schedule: PropTypes.oneOfType([
    PropTypes.bool.isRequired,
    PropTypes.array.isRequired
  ]),
  date_range: PropTypes.oneOfType([
    PropTypes.bool.isRequired,
    PropTypes.string.isRequired
  ]),
  stream_url: PropTypes.oneOfType([
    PropTypes.bool.isRequired,
    PropTypes.string.isRequired
  ])
}

export default withContext(ScheduleDateBlock);