import React from 'react';
import PropTypes from 'prop-types';
import { ComponentWrapper } from './TopGodsCard.css';

const TopGodsCard = (props) => {
  const { gods } = props;
	return (
    <ComponentWrapper className="c-TopGodsCard">
      <h4>Most Played Gods (Current Season)</h4>
      <div className="gods-wrapper">
        {gods && gods.map((item)=>
          <div key={item.name} className="god item">
            <img src={`https://webcdn.hirezstudios.com/smite/god-icons/${item.name.toLowerCase().replace(' ','-')}.jpg`} alt={item.name} />
            <p>{item.name}</p>
            <strong>{`${item.rate}%`}</strong>
          </div>
        )}
      </div>
    </ComponentWrapper>
	); 
};

TopGodsCard.propTypes = {
  gods: PropTypes.array.isRequired
}

export default TopGodsCard;