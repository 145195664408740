import styled from 'styled-components';

export const ComponentWrapper = styled.div`
  background: var(--border);
  color: var(--secondary-text);
  cursor: pointer;
  display: inline-flex;
  font-size: 1.4rem;
  font-weight: bold;
  padding: .7rem 1.5rem;
  text-transform: uppercase;
  &.is-active {
    background: var(--text);
    color: var(--background);
  }
  &:hover {
    background: var(--text);
    color: var(--background);
    a {
      color: var(--background);
    }
  }
  a {
    color: var(--secondary-text);
    text-decoration: none;
  }
`;