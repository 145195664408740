import styled from 'styled-components';

export const ComponentWrapper = styled.div`
  background: var(--background);
  box-shadow: 0px .3rem .6rem rgba(0, 0, 0, 0.16);
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 38rem;
  width: 100%;
  h4 {
    padding: 1.5rem 2rem;
    font-family: var(--font-body);
    font-weight: 600;
  }
  .teams {
    align-items: center;
    background: var(--border);
    display: flex;
    justify-content: space-evenly;
    padding: 0.5rem 0;
    text-transform: uppercase;
    .team-1,
    .team-2 {
      align-items: center;
      display: flex;
      justify-content: center;
    }
    .winner {
      font-weight: 600;
    }
    img {
      height: 2rem;
      padding: 0 0.5rem;
      width: 2rem;
    }
  }
  .item {
    align-items: center;
    display: flex;
    font-weight: 600;
    padding: 0 3rem;
    position: relative;
    &.pick, &.ban {
      border-bottom: 1px solid var(--border);
    }
    &.pick {
      .type {
        background: var(--picks);
      } 
    }
    &.ban {
      //background: var(--secondary-background);
      .type {
        background: var(--bans);
      }
    }
    &.right {
      background:linear-gradient(to left, var(--secondary-background) 50%, var(--background) 0%);
    }
    &.left {
      background:linear-gradient(to right, var(--secondary-background) 50%, var(--background) 0%);
      justify-content: flex-end;
      .god {
        flex-direction: row-reverse;
        left: -1.8rem;
        justify-content: flex-end;
      }
    }
    .god {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: relative;
      text-transform: uppercase;
      width: 12rem;
      p {
        color: var(--text);
        font-size: 1.1rem;
        white-space: nowrap;
      }
      a {
        height: 3rem;
      }
      img {
        height: 3rem;
        padding: 0 1rem;
      }
    }
    .type {
      font-size: 1.2rem;
      font-style: italic;
      font-weight: 500;
      left: 15.2rem;
      padding: 0.9rem;
      position: absolute;
      text-align: center;
      text-transform: capitalize;
      width: 4rem;
    }
  }
`;