import React from 'react';
import PropTypes from 'prop-types';
import withContext from 'store/withContext';

import { ComponentWrapper } from './PostStatsHeader.css';

const PostStatsHeader = (props) => {
  const { team_1_name, team_1_kills, team_1_gold, team_1_score, team_2_name, team_2_kills, team_2_gold, team_2_score, in_progress, set_duration} = props;
	return (
    <ComponentWrapper className="c-PostStatsHeader">
      <div className="content-wrapper">
        <div className="team-wrapper">
          <div className="stats">
            <strong className={team_1_score > team_2_score ? "" : "lost"}>{team_1_name}</strong>
            <div>{`K: ${team_1_kills}`}</div>
            <div>{`G: ${team_1_gold}`}</div>
          </div>
          <img src={`https://webcdn.hirezstudios.com/smite-esports/dev/teams/${props.context.darkTheme ? 'dark/' : ''}${team_1_name}.png`} alt={team_1_name} />
        </div>
        <div className="game-result-wrapper">
          <div className="win-loss-wrapper">
          <div className={`team-score ${team_1_score > team_2_score ? 'win' : 'lost'}`}>{team_1_score}</div>
            <div className="game-stats">
              { in_progress === 0 &&
                <strong>Final</strong>
              }
              { in_progress === 1 &&
                <strong>Vs</strong>
              }
            </div>
            <div className={`team-score ${team_1_score > team_2_score ? 'lost' : 'win'}`}>{team_2_score}</div>
          </div>
          <div className="set-duration">{set_duration}</div>
        </div>
        <div className="team-wrapper">
          <img src={`https://webcdn.hirezstudios.com/smite-esports/dev/teams/${props.context.darkTheme ? 'dark/' : ''}${team_2_name}.png`} alt={team_2_name} />
          <div className="stats">
            <strong className={team_1_score > team_2_score ? "lost" : ""}>{team_2_name}</strong>
            <div>{`K: ${team_2_kills}`}</div>
            <div>{`G: ${team_2_gold}`}</div>
          </div>
        </div>
      </div>
    </ComponentWrapper>
	); 
};

PostStatsHeader.propTypes = {
  team_1_name: PropTypes.string.isRequired,
  team_1_kills: PropTypes.number.isRequired,
  team_1_gold: PropTypes.string.isRequired,
  team_1_score: PropTypes.number.isRequired,
  team_2_name: PropTypes.string.isRequired,
  team_2_kills: PropTypes.number.isRequired,
  team_2_gold: PropTypes.string.isRequired,
  team_2_score: PropTypes.number.isRequired,
  in_progress: PropTypes.number.isRequired
}

export default withContext(PostStatsHeader);