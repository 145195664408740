import styled from 'styled-components';

export const ComponentWrapper = styled.div`
  background-size: cover;
  background-position: center center;
  height: 100%;
  max-width: 156.6rem;
  min-height: 36rem;
  position: relative;
  width: 100%;
  margin-bottom: 13rem;
   @media only screen and (max-width: 768px) {
     background-position: right;
     min-height: 14.4rem;
     .mobile-gradient {
        background: linear-gradient(to bottom,rgba(0,0,0,0) 0%,rgba(0,0,0,0.9) 100%), linear-gradient(to bottom,rgba(0,0,0,0) 0%,rgba(0,0,0,0.9) 100%);
        width: 100%;
        position: absolute;
        bottom: 0;
        height: 9rem;
        display: block;
     }
   }
  .info {
    box-sizing: border-box;
    display: flex;
    position: relative;
    top: 22rem;
    padding-left: 7rem;
     @media only screen and (max-width: 768px) {
       top: 9rem;
     }
  }
  .image {
    background: var(--border);
    border: .5rem solid white;
    border-radius: 15rem;
    height: 20rem;
    overflow: hidden;
    width: 20rem;
    @media only screen and (max-width: 768px) {
      margin-top: 3rem;
      height: 8.9rem;
      width:8.9rem;
    }
    img {
      height: auto;
      width: 100%;
    }
  }
  .details {
    color: white;
    display: flex;
    flex-direction: column;
    font-size: 2.4rem;
    margin-left: 2rem;
    max-height: 8rem;
    padding-top: 5rem;
     @media only screen and (max-width: 768px) {
       font-size: 1.2rem;
       padding-top: 6rem;
     }
    p {
      margin: 0;
    }
    strong {
      font-size: 3rem;
      font-weight: bold;
      margin-bottom: 1rem;
      text-transform: uppercase;
       @media only screen and (max-width: 768px) {
        font-size: 1.4rem;
       }
    }
  }
  .bottom {
    position: relative;
    left: 30rem;
    top: 15rem;
    @media only screen and (max-width: 768px) {
      background: var(--text);
      display: flex;
      justify-content: center;
      left: initial;
      top: 9rem;
    }
    .links {
    display: flex;
    padding-top: 1.6rem;
    @media only screen and (max-width: 768px) {
      padding: 1.5rem;
    }
    a {
      color: var(--text);
      text-decoration: none;
       @media only screen and (max-width: 768px) {
         color: var(--background);
       }
      &:not(:last-of-type) {
        margin-right: 1rem;
      }
      &.mixer {
        align-items: center;
        border: 4px solid;
        border-radius: 50px;
        display: flex;
        padding: 0px 0px 0 5px;
      }
      i {
        font-size: 4rem;
        &.icon-mixer {
          font-size: 2rem;
        }
      }
    }
  }
`;