import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { ComponentWrapper } from './Button.css';

const Button = (props) => {
  const { active, copy, type, url } = props;
	return (
    <ComponentWrapper className={`c-Button ${active ? 'is-active' : ''}`}>
      { type === 'anchor' &&
        <a href={url}>
          {copy}
        </a>
      }
      { type === 'link' &&
        <Link to={url}>
          {copy}
        </Link>
      }
      { type === 'button' &&
        <div>
          {copy}
        </div>
      }
    </ComponentWrapper>
	); 
};

Button.propTypes = {
  active: PropTypes.string,
  copy: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  url: PropTypes.string,
}

export default Button;