import React from 'react';
import PropTypes from 'prop-types';
import { ComponentWrapper } from './TopPlayersCard.css';

const TopPlayersCard = (props) => {
  const { data } = props;
	return (
    <ComponentWrapper className="c-TopPlayersCard">
      <h4>Top Players</h4>
      <div className="player-wrapper">
        {data && data.map((item) => 
          <div key={item.name} className="player-card">
            <div className="cc_image_container fallback">
              <img
                className="cc_image"
                src={`https://webcdn.hirezstudios.com/smite-esports/players/${item.name.toLowerCase()}.png`}
                style={{ backgroundImage: "url('https://webcdn.hirezstudios.com/smite-esports/players/unknown.png')" }}
                alt=""
              />
              <img className="team-image" src={`https://webcdn.hirezstudios.com/smite-esports/dev/teams/${item.team_shortname}.png`}alt=""/>
            </div>
            <div className="info">
              <div>{item.name}</div>
              <div>{`${item.team_shortname} | ${item.role}`}</div>
            </div>
            <div className="stats">{item.display}</div>
          </div>
        )}
      </div>
    </ComponentWrapper>
	); 
};

TopPlayersCard.propTypes = {
  data: PropTypes.array.isRequired
}

export default TopPlayersCard;