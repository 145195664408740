import React from 'react';
import PropTypes from 'prop-types';
import { ComponentWrapper } from './PlayerHeader.css';

const PlayerHeader = (props) => {
  const { banner_image, game_name, name, role, links, team } = props;
	return (
    <ComponentWrapper className="c-PlayerHeader" style={{backgroundImage: `url(${banner_image ? banner_image : ''})`}}>
    <div className="mobile-gradient" />
      <div className="info">
        <div className="image">
          <img src={`https://webcdn.hirezstudios.com/smite-esports/players/${game_name.toLowerCase()}.png`} alt={game_name} />
        </div>
        <div className="details">
          <strong>{game_name}</strong>
          <p>{`${name} | ${team} | ${role}`}</p>
        </div>
      </div>
      <div className="bottom">
        {links &&
          <div className="links">
            {links && links.map((link, i) => 
            <React.Fragment key={link.service + i}>
            {link.url &&
            <a className={`${link.service.toLowerCase()}`} href={link.url} target="_blank" >
              <i className={`icon-${link.service.toLowerCase()}`} />
            </a>
            }
            </React.Fragment>
            )}
          </div>
        }
      </div>
    </ComponentWrapper>
	); 
};

PlayerHeader.propTypes = {
  banner_image: PropTypes.string.isRequired,
  game_name: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  links: PropTypes.array,
  team: PropTypes.string.isRequired
}

export default PlayerHeader;