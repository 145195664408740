import styled from 'styled-components';

export const ComponentWrapper = styled.div`
  background: var(--background);
  border: 1px solid var(--border);
  box-shadow: 0px .3rem .6rem rgba(0, 0, 0, 0.16);
  box-sizing: border-box;
  max-width: 58.6rem;
  width: 100%;
  h4 {
    border-bottom: .1rem solid var(--border);
    font-family: var(--font-body);
    font-size: 1.8rem;
    font-weight: bold;
    padding: 1.5rem 2rem;
  }
  .gods-wrapper {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 2.6rem 0;
    .item {
      align-items: center;
      display: flex;
      flex-direction: column;
      font-weight: bold;
      justify-content: center;
      margin: 0 1.5rem;
      img {
        border: .3rem solid var(--primary);
        border-radius: 20rem;
        height: 15rem;
        width: 15rem;
        @media only screen and (max-width: 768px) {
          height: 10rem;
          width: 10rem;
        }
      }
      p {
        font-size: 1.8rem;
        padding: 1.4rem 0;
        text-transform: uppercase;
        @media only screen and (max-width: 768px) {
          font-size: 1.2rem;
        }
      }
      strong {
        font-size: 3rem;
        @media only screen and (max-width: 768px) {
          font-size: 1.8rem;
        }
      }
    }
  }
`;