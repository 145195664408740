import React from 'react';

import NewsFeatured from 'components/Home/NewsFeatured';
import SocialCard from 'components/Global/SocialCard';
import {StandingsCard} from 'components/Global/StandingsCard';
import VerticalPromoCard from 'components/Home/VerticalPromoCard';
import MatchScheduleCard from 'components/Global/MatchScheduleCard';
import NewsItem from 'components/Home/NewsItem';
import NewsCard from 'components/News/NewsCard';
import TeamCard from 'components/Teams/TeamCard';
import Button from 'components/Global/Button';
import ScheduleDateBlock from 'components/Schedule/ScheduleDateBlock';
import TextNavigationList from 'components/Global/TextNavigationList';
import StatsTable from 'components/Global/StatsTable';
import PlayerHeader from 'components/Players/PlayerHeader';
import TopGodsCard from 'components/Players/TopGodsCard'
import MatchSummaryCard from 'components/Scores/MatchSummaryCard';
import PostStatsHeader from 'components/Stats/PostStatsHeader';
import PicksBansCard from 'components/Stats/PicksBansCard';
import TopPlayersCard from 'components/Stats/TopPlayersCard';

import withContext from 'store/withContext';


const Components = (props) => {
  return (
    <div className="components">
      <TopPlayersCard 
        data={[
          {
            "name": "Benjamin Knight",
            "in_game": "CaptainTwig",
            "role": "Hunter",
            "stat": "221 Kills"
          },
          {
            "name": "Kenny Kuska",
            "in_game": "Arkkyl",
            "role": "Jungle",
            "stat": "570 Gold"
          }
        ]}
      />
      {/* <PicksBansCard 
        team_1_name="SSG"
        team_1_score="1"
        team_2_name="DIG"
        team_2_score="0"
        data={[
          "Artio",
          "Freya",
          "Sylvannus",
          "Ah Puch",
          "King Arthur",
          "Ne Zha",
          "Xbalanque",
          "Jing Wei",
          "Terra",
          "Janus",
          "Thoth",
          "Fafnir",
          "Raijin",
          "Geb",
          "Ao Kuang",
          "Serqet",
          "Hera",
          "Thor"
        ]}
      /> */}
      <PostStatsHeader 
        team_1_name="SSG"
        team_1_kills="52"
        team_1_gold="1500"
        team_1_score="3"
        team_2_name="DIG"
        team_2_kills="1500"
        team_2_gold="52"
        team_2_score="0"
        time="00:45:34"
      />
      {/* <MatchSummaryCard 
        data={[
          {
            "name":"SSG",
            "score": 3,
            "top_player":"Andinster",
            "summary":"Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores.",
            "video_id": "000000",
            "match_id":"000000",
          },
          {
            "name":"DIG",
            "score": 0,
            "top_player":"Ataraxia",
            "summary":"Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores.",
            "video_id": "000000",
            "match_id":"000000",
          }
        ]}
      /> */}
      <TopGodsCard 
        gods={[
          {
            "name":"Janus",
            "rate": 80
          },
          {
            "name":"Anubis",
            "rate": 15
          },
          {
            "name":"Neith",
            "rate": 5
          }
        ]}  
      />
      <PlayerHeader 
        banner_image="https://webcdn.hirezstudios.com/esports/dev/player-header-1566x360.png"
        game_name="Pandacat"
        image="https://webcdn.hirezstudios.com/esports/dev/player-image-200x200.png"
        name="Maksim Yanevich"
        role="Mid"
        links={[
          {
            "service":"facebook",
            "url":"http://facebook.com"
          },
          {
            "service":"instagram",
            "url":"http://instagram.com"
          },
          {
            "service":"twitter",
            "url":"http://twitter.com"
          },
          {
            "service":"youtube",
            "url":"http://youtube.com"
          },
          {
            "service":"twitch",
            "url":"http://twitch.com"
          }
        ]}
        team="Team Rival"
      />
      <StatsTable 
        headings={[`${process.env.GATSBY_LEAGUE_HEADER_LONG} SEASON 9`,'Matches','Wins','Losses','Win%','Total Points','Next Game']}
        data={[
          {
            "name":"Team Rival",
            "matches":"14",
            "wins":"11",
            "losses":"3",
            "win_percent":"78%",
            "points":"1140",
            "next_game":"Sep 28 vs SSG",
          },
          {
            "name":"Dignitas",
            "matches":"15",
            "wins":"15",
            "losses":"5",
            "win_percent":"66%",
            "points":"1100",
            "next_game":"Sep 22 vs DIG",
          }
        ]}
        showIndex={true}
      />
      <TextNavigationList 
        data={['Overview','Players','Teams']}
        activeCallback={(e)=>console.log(e)}
      />
      <ScheduleDateBlock
        schedule={props.context.schedule !== null && props.context.schedule.schedule}
        phase={process.env.GATSBY_LEAGUE_PHASE}
        date_range={props.context.schedule !== null && props.context.schedule.date_range}
        stream_url={props.context.schedule !== null && props.context.schedule.stream_url} 
      />
      <Button 
        active={true}
        copy="Current Schedule"
        type="button"
      />
      <Button 
        copy="All Dates"
        type="link"
        url="/schedule"
      />
      <Button 
        copy="External Dates"
        type="anchor"
        url="https://google.com"
      />
      <TeamCard 
        logo="https://webcdn.hirezstudios.com/esports/teams/rvl-large.png"
        name="Team Rival"
        tag="RvL"
      />
      <NewsCard
        date="2 Months Ago"
        excerpt="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam."
        slug="headline-goes-here"
        thumbnail="https://webcdn.hirezstudios.com/esports/dev/card-thumbnail-300x200.png"
        title="Community Report: LG VS Obey Broadcast Issues"
        type="news"
      />
      <NewsItem
        date="2 Months Ago"
        excerpt="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam."
        slug="headline-goes-here"
        thumbnail="https://img.youtube.com/vi/S95oH7V98oc/maxresdefault.jpg"
        title="Headline Goes Here"
        type="video"
        video_id="S95oH7V98oc"
      />
      <NewsItem
        date="2 Months Ago"
        excerpt="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam."
        slug="headline-goes-here"
        thumbnail="https://webcdn.hirezstudios.com/esports/dev/test-thumbnail-178x100.png"
        title="Headline Goes Here"
        type="news"
        video_id="" // type = video
      />
      <MatchScheduleCard
        stream_url="https://twitch.tv/smitegame"
        data={props.context.schedule !== null && props.context.schedule.schedule}
      />
      <VerticalPromoCard
        desktop_image="https://webcdn.hirezstudios.com/esports/dev/vert-ad-250x762.png"
        mobile_image="https://webcdn.hirezstudios.com/esports/dev/hor-ad-530x150.png"
        url="https://google.com/"
      />
      <StandingsCard
        season={process.env.GATSBY_LEAGUE_HEADER}
        data={props.context.standings !== null && props.context.standings}
      />
      <SocialCard 
        heading={`Follow SMITE Esports`}
        copy="Stay connected with the latest news and scores"
        links={[
          {
            "service":"facebook",
            "url":"http://facebook.com"
          },
          {
            "service":"instagram",
            "url":"http://instagram.com"
          },
          {
            "service":"x",
            "url":"http://twitter.com"
          },
          {
            "service":"youtube",
            "url":"http://youtube.com"
          },
          {
            "service":"twitch",
            "url":"http://twitch.com"
          }
        ]}
      />
      <NewsFeatured data={[
        {
          "date": "2 Months Ago",
          "excerpt":"Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam.",
          "slug":"headline-goes-here",
          "image":"https://webcdn.hirezstudios.com/esports/dev/featured-image-1000x500.png",
          "title":"Headline Goes Here 1",
          "type":"news",
          "video_id":"",
        },
        {
          "date": "2 Months Ago",
          "excerpt":"Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam.",
          "slug":"headline-goes-here",
          "image":"https://webcdn.hirezstudios.com/esports/dev/featured-image-1000x500.png",
          "title":"Headline Goes Here 2",
          "type":"news",
          "video_id":"",
        },
        {
          "date": "2 Months Ago",
          "excerpt":"Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam.",
          "slug":"headline-goes-here",
          "image":"https://webcdn.hirezstudios.com/esports/dev/featured-image-1000x500.png",
          "title":"Headline Goes Here 3",
          "type":"news",
          "video_id":"",
        }
      ]} />
    </div>
  );
};

export default withContext(Components);
